<template>
  <div class="req-temp-container">
    <!-- group name card starts here -->
    <vs-row class="mt-5">
      <vs-col vs-w="12">
        <vs-card class="">
          <div slot="header">
            <h3 class="font-normal">
              Group name <span class="mid-blue">*</span>
            </h3>
          </div>
          <div>
            <vs-row>
              <p class="text-base">
                This will appear as the display name for this group.
              </p>
            </vs-row>
            <vs-row class="mt-4">
              <vs-col vs-w="3" vs-lg="3" vs-sm="12" class="lg:pr-5 sm:pr-0">
                <div class="input-container mb-5">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('groupName')"
                    >{{ errors.first("groupName") }}</span
                  >
                  <vs-input
                    class="w-full"
                    v-validate="'required'"
                    data-vv-as="group name"
                    data-vv-validate-on="blur"
                    size="large"
                    v-model="groupName"
                    name="groupName"
                    required
                  />
                  <label class="w-full"
                    >Group name <span class="mid-blue">*</span></label
                  >
                </div>
              </vs-col>
            </vs-row>
          </div>
        </vs-card>
      </vs-col>
    </vs-row>

    <!-- group name card ends here -->

    <!-- assign users card starts here -->
    <vs-row>
      <vs-col vs-w="12">
        <vs-card class="assign-users">
          <div slot="header">
            <h3 class="font-normal">
              Assign users <span class="mid-blue">*</span>
            </h3>
          </div>
          <div>
            <vs-row
              ><p class="text-base">
                Select users that will belong to this group.
              </p>
            </vs-row>

            <vs-row class="mb-4 mt-6 " style="max-width:1210px;">
              <div class="flex items-center">
                <vs-checkbox
                  class="float-left"
                  @change="selectAllUsers()"
                ></vs-checkbox
                ><span
                  class="
                    font-normal
                    lg:text-base
                    md:text-base
                    text-sm text-black
                  "
                  >Assign All Users</span
                >
              </div>
              <div class="ml-auto">
                <vs-input
                ref="input"
                class="custom-user-search"
                v-model="searchQuery"
                icon-pack='feather'
                icon='icon-search'
                icon-no-border />
              </div>
            </vs-row>

            <!-- users list with checkbox starts here -->
            <vs-table
              :data="resultQuery"
              class="assign-users-table stripes"
            >
              <template slot="thead">
                <vs-th class="text-base">Assign</vs-th>
                <vs-th class="text-base"> Name </vs-th>
                <vs-th class="text-base"> Role </vs-th>
                <vs-th class="text-base"> Email </vs-th>
                <vs-th class="text-base"> Mobile </vs-th>
                <vs-th
                  v-if="user.requestOptions.includes('pay-now')"
                  class="text-base"
                >
                  Can refund
                </vs-th>
              </template>
              <template slot-scope="{ data }">
                <vs-tr :key="indextr" v-for="(merchantUser, indextr) in data">
                  <vs-td>
                    <vs-checkbox
                      v-model="checkedUsers"
                      :vs-value="merchantUser._id"
                      @change="userAssign(merchantUser)"
                    ></vs-checkbox>
                  </vs-td>
                  <vs-td :data="merchantUser.fullName">
                    {{
                      merchantUser.fullName
                        ? merchantUser.fullName
                        : merchantUser.systemName
                    }}
                  </vs-td>
                  <vs-td :data="merchantUser.userType">
                    {{
                      merchantUser.userType === "admin"
                        ? "Super Admin"
                        : merchantUser.userType === "staff"
                        ? "User"
                        : merchantUser.userType === "staffAdmin"
                        ? "Admin"
                        : "API User"
                    }}
                  </vs-td>
                  <vs-td :data="merchantUser.reference">
                    {{ merchantUser.email }}
                  </vs-td>
                  <vs-td :data="merchantUser.companyName">
                    {{ merchantUser.phoneNumber }}
                  </vs-td>
                  <vs-td v-if="user.requestOptions.includes('pay-now')">
                    <vs-checkbox
                      v-model="merchantUser.canRefund"
                      :vs-value="true"
                      @change="userRefund(merchantUser, $event)"
                      :disabled="isDisabled(merchantUser._id)"
                    ></vs-checkbox>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
            <span
              class="text-danger text-sm"
              v-show="errors.has('checkedUsers')"
              >{{ errors.first("checkedUsers") }}</span
            >
            <!-- users list with checkbox ends here -->
          </div>
        </vs-card>
      </vs-col>
    </vs-row>

    <!-- assign users card ends here -->

    <!-- permissions card starts here -->
    <vs-row>
      <vs-col vs-w="12">
        <vs-card class="">
          <div slot="header">
            <h3 class="font-normal">Permissions</h3>
          </div>

          <!-- payment request templates group starts here -->
          <div>
            <vs-row>
              <h3 class="font-normal text-xl">Payment requests</h3>
              <hr class="line-hr my-4 w-full" />
            </vs-row>

            <vs-row class="my-6">
              <p class="text-base font-normal">
                Enable the payment request templates you’d like to give users in
                this group access to.
              </p>
            </vs-row>

            <vs-row>
              <vs-select
                placeholder="Select Request Templates"
                multiple
                autocomplete
                class="selectExample"
                @click="checkForm"
                v-model="selectedRequestTemplates"
              >
                <div class="selectAllDiv">
                  <a
                    class="dropdownSelectAllButton"
                    @click="selectAllRequestTemplate"
                    >{{
                      !allPrTemplatesSelected ? `Select all` : `Unselect all`
                    }}</a
                  >
                </div>

                <vs-select-item
                  :key="index"
                  :value="requestTemplate"
                  :text="requestTemplate.templateName"
                  v-for="(requestTemplate, index) in requestTemplates"
                />
              </vs-select>
            </vs-row>

            <vs-row class="mt-8 mb-4 grp-card-grid">
              <div
                v-for="(requestTemplate, key) in selectedRequestTemplates"
                :key="key"
              >
                <vs-card class="group-card-style mb-0">
                  <div slot="header">
                    <vs-row>
                      <div>
                        <h5 class="font-medium text-xl">
                          {{ requestTemplate.templateName }}
                        </h5>
                        <p class="text-base my-2">
                          {{
                            capitalizeFirstLetter(requestTemplate.templateType)
                          }}
                        </p>
                        <p class="text-base">
                          ID: {{ requestTemplate.requestTemplateId }}
                        </p>
                      </div>
                      <span class="element">
                        <a @click="discardRequestTemplate(key)">
                          <x-icon size="1.5x" class="custom-class"></x-icon></a
                      ></span>
                    </vs-row>
                  </div>
                  <div
                    class="flex items-center mb-4"
                    v-if="requestTemplate.linkedAccount"
                  >
                    <img src="@/assets/images/account.svg" class="mr-5" />
                    <p class="darker-blue">
                      {{ requestTemplate.linkedAccount.displayName }}
                    </p>
                  </div>
                  <div
                    class="flex items-center"
                    v-if="requestTemplate.paymentOptions[0]"
                  >
                    <img src="@/assets/images/payment.svg" class="mr-5" />
                    <p class="darker-blue">
                      {{
                        paymentMethods(
                          requestTemplate.paymentOptions[0].requestOptions,
                          requestTemplate
                        )
                      }}
                    </p>
                  </div>
                </vs-card>
              </div>
            </vs-row>
          </div>

          <!-- payment requests group cards ends here -->

          <!-- hosted payment request group starts here -->

          <div class="mt-8" v-if="user.requestOptions.includes('pay-now')">
            <vs-row>
              <h3 class="font-normal text-xl">Hosted payment pages</h3>
              <hr class="line-hr my-4" />
            </vs-row>

            <vs-row class="my-6">
              <p class="text-base font-normal">
                Enable the hosted payment pages you’d like to give users in this
                group access to.
              </p>
            </vs-row>

            <vs-row class="mb-8">
              <vs-select
                placeholder="Select Hosted Payment Pages"
                multiple
                autocomplete
                class="selectExample"
                @click="checkForm"
                v-model="selectedHostedPages"
              >
                <div class="selectAllDiv">
                  <a
                    class="dropdownSelectAllButton"
                    @click="selectAllHostedPages"
                    >{{
                      !allHostedPagesSelected ? `Select all` : `Unselect all`
                    }}</a
                  >
                </div>

                <vs-select-item
                  :key="index"
                  :value="hostedPage"
                  :text="hostedPage.templateName"
                  v-for="(hostedPage, index) in hostedPages"
                />
              </vs-select>
            </vs-row>
            <vs-row class="mt-8 mb-4 grp-card-grid hosted">
              <vs-col
                v-for="(requestTemplate, key) in selectedHostedPages"
                :key="key"
              >
                <vs-card class="group-card-style">
                  <div slot="header">
                    <vs-row>
                      <vs-col vs-w="8" vs-xs="8">
                        <h5 class="font-medium text-xl">
                          {{ requestTemplate.templateName }}
                        </h5>
                        <p class="text-base mt-2">
                          ID: {{ requestTemplate.requestTemplateId }}
                        </p>
                      </vs-col>
                      <span class="element">
                        <a @click="discardHostedPages(key)">
                          <x-icon size="1.5x" class="custom-class"></x-icon></a
                      ></span>
                    </vs-row>
                  </div>
                  <div class="flex items-center mb-4">
                    <img src="@/assets/images/account.svg" class="mr-5" />
                    <p class="darker-blue">
                      {{ requestTemplate.linkedAccount.displayName }}
                    </p>
                  </div>
                  <div class="flex items-center">
                    <img src="@/assets/images/payment.svg" class="mr-5" />
                    <p class="darker-blue">
                      {{
                        paymentMethods(
                          requestTemplate.paymentOptions[0].requestOptions,
                          requestTemplate
                        )
                      }}
                    </p>
                  </div>
                </vs-card>
              </vs-col>
            </vs-row>
          </div>

          <!-- hosted payment request group ends here -->
        </vs-card>
      </vs-col>
    </vs-row>

    <!-- permissions card ends here -->

    <vs-row class="mt-8" style="max-width: 1280px">
      <vs-col vs-w="12" class="flex items-center justify-end">
        <vs-button
          size="large"
          v-if="groupId && isAdminLogin"
          @click="popupDelActive = true"
          class="only-border-btn mr-8"
          v-round
          >Delete Group</vs-button
        >

        <vs-button
          class="mr-8"
          size="large"
          :disabled="groupName.length == 0 || checkedUsers.length == 0"
          @click="saveGroup"
          v-round
          >{{ groupId ? "Update" : "Save" }}</vs-button
        >

        <a color="danger" @click="onCancel" class="edit-profile" v-round
          >Cancel</a
        >
      </vs-col>
    </vs-row>
    <leave-popup
      :popup-active="popupActive"
      @handleClick="popActions"
    ></leave-popup>

    <pop-up :errorsList="errors" form-name="GroupsForm"> </pop-up>

    <delete-popup
      :popup-active="popupDelActive"
      :warningContent="warningContent"
      :deleteWhat="deleteWhat"
      :buttonText="buttonText"
      :found="warningFound"
      @handleDeleteClick="delActions"
    ></delete-popup>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { Validator } from "vee-validate";
import { TheMask } from "vue-the-mask";
import LeavePopup from "@/views/components/LeavePopup";
import PopUp from "@/views/components/ValidationPopup.vue";
import { UserIcon, UsersIcon, EyeIcon, XIcon } from "vue-feather-icons";
import DeletePopup from "@/views/components/DeletePopup";
import { leavePopUpMixin } from "@/mixins/leave-pop-up";

const dictionary = {
  en: {
    attributes: {
      phoneNumber: "phone number",
      firstName: "first name",
      lastName: "last name",
    },
    messages: {
      regex: () => "Phone number should start with 04 and should be 10 digits",
    },
  },
};
Validator.localize(dictionary);
export default {
  mixins: [leavePopUpMixin],
  components: {
    TheMask,
    PopUp,
    LeavePopup,
    UserIcon,
    UsersIcon,
    EyeIcon,
    XIcon,
    DeletePopup,
  },
  data() {
    return {
      groupType: "ui",
      groupId: "",
      allUsersSelected: false,
      allPrTemplatesSelected: false,
      allHostedPagesSelected: false,

      groupName: "",
      merchantUsers: [],
      checkedUsers: [],
      checkedUsersAccess: [],
      existingUsers: [],
      addUserArray: [],
      removeUserArray: [],
      requestTemplate: "",
      requestTemplates: [],
      selectedRequestTemplates: [],

      hostedPages: [],
      selectedHostedPages: [],
      disableViewOnly: false,
      merchantId: "",
      userIds: [],
      allSelected: false,
      useTheme: false,

      searchText: "",
      accessTypeForCss: "",
      serverUrl: process.env.VUE_APP_API_URL,
      isSaved: false,
      nextObj: "", // determines the next route,
      popupActive: false, // determines if the leave popup is to be shown
      showValidationPopUp: false, // determines if the leave popup is to be shown
      selectedTemplates: [],
      initialTemplateArray: [],
      removeTemplateArray: [],
      popupDelActive: false,
      warningFound: true,
      warningContent:
        "Deleting this group will not delete the user accounts within it, only the permissions assigned to them. You may need to re-assign the users, payment request templates and hosted payment pages that belonged to this group to another group.",
      deleteWhat: "user group",
      buttonText: "Delete Group",
      isAdminLogin: false,
      checkFormDirty: false,
      searchQuery: null,
    };
  },
  methods: {
    ...mapActions("paymentRequestTemplate", [
      "fetchRequestTemplateByMerchantId",
      "fetchUnassignedTemplate",
      "fetchUnassignedTemplateById",
    ]),
    ...mapActions("merchantGroup", [
      "saveMerchantGroup",
      "updateMerchantGroup",
      "fetchMerchantGroupByMerchantId",
      "fetchMerchantGroupById",
      "deleteMerchantGroup",
    ]),

    ...mapActions("partner", [
      "fetchStaffByPartnerId",
      "fetchStaffDetailsById",
      "updateStaffDetailsById",
      "createStaff",
    ]),

    discardRequestTemplate(index) {
      if (index > -1) {
        this.selectedRequestTemplates.splice(index, 1);
      }
      this.checkFormDirty = true;
    },

    discardHostedPages(index) {
      if (index > -1) {
        this.selectedHostedPages.splice(index, 1);
      }
      this.checkFormDirty = true;
    },

    onCancel() {
      this.$router.push({ name: "partner-groups" });
    },

    paymentMethods(options, data) {
      let paymentOptions = [];
      if (options.includes("pay-now")) {
        paymentOptions.push("Pay Now");
      }
      if (options.includes("pay-later")) {
        let productName = ""
        if(data && data.planName){
          productName = Array.isArray(data.planName) && data.planName[0] ? data.planName[0].productName : "" 
        }
        paymentOptions.push(productName);
      }
      return paymentOptions.join(", ");
    },

    capitalizeFirstLetter(string) {
      string = string.charAt(0).toUpperCase() + string.slice(1);
      return string + " template";
    },

    // ! functions for users start here
    async getMerchantUsers() {
      await this.fetchStaffByPartnerId(this.merchantId)
        .then((res) => {
          this.merchantUsers = res.data.data;
          this.merchantUsers = this.merchantUsers.map((element) => ({
            ...element,
            canRefund: false,
          }));
        })
        .catch((err) => {});
    },
    async getMerchantGroupById() {
      await this.fetchMerchantGroupById(this.groupId)
        .then((res) => {
          this.groupName = res.data.data.groupName;
          this.checkedUsers = res.data.data.users;
          if (res.data.data.userAccess && res.data.data.userAccess.length > 0) {
            this.checkedUsersAccess = res.data.data.userAccess;
          } else if (this.checkedUsers.length > 0) {
            this.checkedUsersAccess = this.checkedUsers.map((element) => ({
              user: element,
              canRefund: false,
            }));
          }
          this.existingUsers = res.data.data.users;
          const reqTem = res.data.data.permissionsOnRequestTemplate.map(
            (element) => {
              for (let i = 0; i < this.requestTemplates.length; i++) {
                if (element.requestTemplateId == this.requestTemplates[i]._id) {
                  this.requestTemplates[i].accessType = "group";
                  this.requestTemplates[i].viewOnly = false;
                  this.requestTemplates[i].useThemeGroup = true;
                  this.requestTemplates[i].useThemeIndividual = false;
                  this.requestTemplates[i].disableViewOnly = false;
                  this.requestTemplates[i].showIndividualMsg = false;
                  this.requestTemplates[i].showGroupEditableMsg = this
                    .requestTemplates[i].viewOnly
                    ? false
                    : true;
                  this.requestTemplates[i].showGroupViewOnlyMsg = this
                    .requestTemplates[i].viewOnly
                    ? true
                    : false;

                  this.selectedRequestTemplates.push(this.requestTemplates[i]);
                  this.initialTemplateArray.push(this.requestTemplates[i]);
                }
              }
            }
          );

          const host = res.data.data.permissionsOnHostedPages.map((element) => {
            for (let i = 0; i < this.hostedPages.length; i++) {
              if (element.hostedPageId == this.hostedPages[i]._id) {
                this.hostedPages[i].viewOnly = false;
                this.hostedPages[i].viewAndRefund = true;
                this.hostedPages[i].showViewOnlyMsg = false;
                this.hostedPages[i].showViewAndRefundMsg = true;
                this.hostedPages[i].useThemeViewOnly = false;
                this.hostedPages[i].useThemeViewAndRefund = true;
                this.selectedHostedPages.push(this.hostedPages[i]);
                this.initialTemplateArray.push(this.hostedPages[i]);
              }
            }
          });

          this.checkedUsersAccess.forEach((element) => {
            let objIndex = this.merchantUsers.findIndex(
              (obj) => obj._id == element.user
            );
            if (objIndex >= 0) {
              this.merchantUsers[objIndex].canRefund = element.canRefund;
            }
          });
        })
        .catch((err) => {
           this.$vs.notify({
              title: "Group",
              text: err.response.data.message,
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger",
              position: "top-right"
            });
            if(err.response.status == 404) {
              this.$router.push({ name: "partner-groups" });
            }
        });
    },

    selectAllUsers() {
      this.allUsersSelected = !this.allUsersSelected;
      if (this.allUsersSelected) {
        const allMerchandUsersId = this.merchantUsers.map((element) => {
          return element._id;
        });
        this.checkedUsers = [...allMerchandUsersId];
      } else {
        this.checkedUsers = [];
      }
      this.checkFormDirty = true;
    },

    // !Functions for users ends here

    // ! Functions for PR template permitions start here
    selectAllRequestTemplate() {
      this.allPrTemplatesSelected = !this.allPrTemplatesSelected;
      if (this.allPrTemplatesSelected) {
        this.selectedRequestTemplates = [...this.requestTemplates];
      } else {
        this.selectedRequestTemplates = [];
      }
      this.checkFormDirty = true;
    },

    selectAllHostedPages() {
      this.allHostedPagesSelected = !this.allHostedPagesSelected;
      if (this.allHostedPagesSelected) {
        this.selectedHostedPages = [...this.hostedPages];
      } else {
        this.selectedHostedPages = [];
      }
      this.checkFormDirty = true;
    },

    selectAccessType(el, index) {
      this.selectedRequestTemplates[index].accessType = el;
      if (el == "individual") {
        this.selectedRequestTemplates[index].viewOnly = false;
        this.selectedRequestTemplates[index].useThemeIndividual = true;
        this.selectedRequestTemplates[index].useThemeGroup = false;
        this.selectedRequestTemplates[index].disableViewOnly = true;
        this.selectedRequestTemplates[index].showIndividualMsg = true;
        this.selectedRequestTemplates[index].showGroupEditableMsg = false;
        this.selectedRequestTemplates[index].showGroupViewOnlyMsg = false;
      }
      if (el == "group") {
        this.selectedRequestTemplates[index].useThemeGroup = true;
        this.selectedRequestTemplates[index].useThemeIndividual = false;
        this.selectedRequestTemplates[index].disableViewOnly = false;
        this.selectedRequestTemplates[index].showIndividualMsg = false;
        this.selectedRequestTemplates[index].showGroupEditableMsg = this
          .selectedRequestTemplates[index].viewOnly
          ? false
          : true;
        this.selectedRequestTemplates[index].showGroupViewOnlyMsg = this
          .selectedRequestTemplates[index].viewOnly
          ? true
          : false;
      }
      this.checkFormDirty = true;
    },

    viewOnlyFunc(index) {
      if ((this.selectedRequestTemplates[index].showGroupEditableMsg = true)) {
        this.selectedRequestTemplates[index].disableViewOnly = false;
        this.selectedRequestTemplates[index].showIndividualMsg = false;
        this.selectedRequestTemplates[index].showGroupEditableMsg = false;
        this.selectedRequestTemplates[index].showGroupViewOnlyMsg = true;
      }
    },

    // select view only or refund on hosted pages
    selectViewOnlyOrRefund(el, index) {
      if (el == "viewOnly") {
        this.selectedHostedPages[index].viewOnly = true;
        this.selectedHostedPages[index].viewAndRefund = false;
        this.selectedHostedPages[index].showViewOnlyMsg = true;
        this.selectedHostedPages[index].showViewAndRefundMsg = false;
        this.selectedHostedPages[index].useThemeViewOnly = true;
        this.selectedHostedPages[index].useThemeViewAndRefund = false;
      }
      if (el == "viewAndRefund") {
        this.selectedHostedPages[index].viewOnly = false;
        this.selectedHostedPages[index].viewAndRefund = true;
        this.selectedHostedPages[index].showViewOnlyMsg = false;
        this.selectedHostedPages[index].showViewAndRefundMsg = true;
        this.selectedHostedPages[index].useThemeViewOnly = false;
        this.selectedHostedPages[index].useThemeViewAndRefund = true;
      }
    },

    async getRequestTemplateByMerchantId() {
      let data = {
        merchantId: this.merchantId,
        type: "template",
        groupId: this.$route.params.id ? this.$route.params.id : "",
      };
      await this.fetchUnassignedTemplate(data).then((res) => {
        this.requestTemplates = res.data.data;
        this.requestTemplates = this.requestTemplates.map((element) => ({
          ...element,
          disableViewOnly: false,
          showIndividualMsg: false,
          showGroupEditableMsg: true,
          showGroupViewOnlyMsg: false,
          useThemeIndividual: false,
          useThemeGroup: true,
          accessType: "group",
        }));
        if (this.$route.params.id) {
          this.getMerchantGroupById();
        }
      });
    },

    async getRequestTemplateByMerchantIdGroup() {
      let data = {
        merchantId: this.merchantId,
        type: "template",
        groupId: this.$route.params.id,
      };
      await this.fetchUnassignedTemplateById(data).then((res) => {
        this.requestTemplates = res.data.data;
        this.requestTemplates = this.requestTemplates.map((element) => ({
          ...element,
          disableViewOnly: false,
          showIndividualMsg: false,
          showGroupEditableMsg: true,
          showGroupViewOnlyMsg: false,
          useThemeIndividual: false,
          useThemeGroup: true,
          accessType: "group",
        }));
        if (this.$route.params.id) {
          this.getMerchantGroupById();
        }
      });
    },

    async getHostedPaymentPageByMerchantId() {
      let data = {
        merchantId: this.merchantId,
        type: "hostedpage",
      };
      await this.fetchUnassignedTemplate(data).then((res) => {
        this.hostedPages = res.data.data;
        this.hostedPages = this.hostedPages.map((element) => ({
          ...element,
          link: "",
          viewOnly: false,
          viewAndRefund: true,
          showViewOnlyMsg: false,
          showViewAndRefundMsg: true,
          useThemeViewOnly: false,
          useThemeViewAndRefund: true,
        }));
      });
    },

    async getHostedPaymentPageByMerchantIdGroup() {
      let data = {
        merchantId: this.merchantId,
        type: "hostedpage",
        groupId: this.$route.params.id,
      };
      await this.fetchUnassignedTemplateById(data).then((res) => {
        this.hostedPages = res.data.data;
        this.hostedPages = this.hostedPages.map((element) => ({
          ...element,
          link: "",
          viewOnly: false,
          viewAndRefund: true,
          showViewOnlyMsg: false,
          showViewAndRefundMsg: true,
          useThemeViewOnly: false,
          useThemeViewAndRefund: true,
        }));
      });
    },
    //! functions for PR template permitions end here

    async saveGroup() {
      const valid = await this.$validator.validate();
      if (!valid) {
        return;
      }
      this.$vs.loading();
      let checkedTemplates = [];
      const permissionsOnRequestTemplate = this.selectedRequestTemplates.map(
        (element) => {
          checkedTemplates.push({
            _id: element._id,
            templateName: element.templateName,
            accessType: element.accessType,
            viewOnly: element.viewOnly ? element.viewOnly : false,
            templateType: element.requestType,
            notifications: element.notifications,
          });
          return {
            requestTemplateId: element._id,
            templateName: element.templateName,
            accessType: element.accessType,
            viewOnly: element.viewOnly ? element.viewOnly : false,
          };
        }
      );

      const permissionsOnHostedPages = this.selectedHostedPages.map(
        (element) => {
          checkedTemplates.push({
            _id: element._id,
            templateName: element.templateName,
            accessType: element.accessType,
            viewOnly: element.viewOnly ? element.viewOnly : false,
            templateType: element.requestType,
            notifications: element.notifications,
          });
          return {
            hostedPageId: element._id,
            templateName: element.templateName,
            viewAndRefund: element.viewAndRefund
              ? element.viewAndRefund
              : false,
            viewOnly: element.viewOnly ? element.viewOnly : false,
          };
        }
      );
      this.selectedTemplates = checkedTemplates;
      let finalTemplates = this.selectedRequestTemplates.concat(
        this.selectedHostedPages
      );
      if (this.initialTemplateArray.length > 0) {
        let removeTemplates = this.initialTemplateArray.filter(
          (template) => !finalTemplates.includes(template)
        );
        this.removeTemplateArray = removeTemplates.map((template) => {
          return template._id;
        });
      }

      this.addUserArray = this.checkedUsers.filter(
        (user) => !this.existingUsers.includes(user)
      );
      let formData = {
        merchantId: this.merchantId,
        groupName: this.groupName,
        users: this.checkedUsers,
        permissionsOnRequestTemplate: permissionsOnRequestTemplate,
        permissionsOnHostedPages: permissionsOnHostedPages,
        groupType: this.groupType,
        selectedTemplates: this.selectedTemplates,
        checkedUsers: this.checkedUsers,
        userAccess: this.checkedUsersAccess,
        addUserArray: this.addUserArray,
      };

      // edit
      if (this.groupId) {
        if (this.user && this.user.adminId && this.user.loginBy) {
          formData.editedByAdmin = this.user.adminId;
        }
        let id = this.groupId;
        this.removeUserArray = this.existingUsers.filter(
          (user) => !this.checkedUsers.includes(user)
        );
        formData.removeUserArray = this.removeUserArray;
        formData.removeTemplateArray = this.removeTemplateArray;
        await this.updateMerchantGroup({ id, formData })
          .then((res) => {
            this.$vs.notify({
              subject: "Update Group Content",
              text: res.data.message,
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "success",
            });
            this.$vs.loading.close();
            this.isSaved = true;
            this.$router.push({ name: "partner-groups" });
          })
          .catch((err) => {
            this.$vs.loading.close();
            this.$vs.notify({
              title: "Group",
              text: err.response.data.message,
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger",
              position: "top-right"
            });
            if(err.response.status == 404) {
              this.isSaved = true;
              this.$router.push({ name: "partner-groups" });
            }
          });
      }
      // save
      if (!this.groupId) {
        if (this.user && this.user.adminId && this.user.loginBy) {
          formData.createdByAdmin = this.user.adminId;
        }
        await this.saveMerchantGroup(formData)
          .then((res) => {
            this.$vs.notify({
              subject: "Save Group Content",
              text: res.data.message,
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "success",
            });
            this.$vs.loading.close();
            this.isSaved = true;
            this.$router.push({ name: "partner-groups" });
          })
          .catch((err) => {
            this.$vs.loading.close();
            
          });
      }
    },

    isDisabled(check) {
      if (this.checkedUsers.includes(check)) {
        return false;
      } else {
        return true;
      }
    },
    userAssign(user) {
      if (this.checkedUsers.includes(user._id)) {
        let refund = user.canRefund;
        if (user.userType === "apiUser" || user.userType === "introducerUser") {
          refund = true;
        }
        user.canRefund = refund;
        this.checkedUsersAccess.push({
          user: user._id,
          canRefund: refund,
        });
      } else {
        user.canRefund = false;
        let objIndex = this.checkedUsersAccess.findIndex(
          (obj) => obj.user == user._id
        );
        if (objIndex >= 0) {
          this.checkedUsersAccess.splice(objIndex, 1);
        }
      }
      this.checkFormDirty = true;
    },
    userRefund(user, event) {
      let objIndex = this.checkedUsersAccess.findIndex(
        (obj) => obj.user == user._id
      );
      if (objIndex >= 0) {
        this.checkedUsersAccess[objIndex].canRefund = !event.target._value;
      }
      this.checkFormDirty = true;
    },
    delActions(event) {
      switch (event) {
        case "delete":
          this.popupDelActive = false;
          this.handleDelete();
          break;
        case "cancel":
          this.popupDelActive = false;
          break;
        default:
          this.popupDelActive = false;
      }
    },
    async handleDelete() {
      this.$vs.loading();
      let formData = {
        deleted: true,
      };
      let id = this.groupId;
      if (this.isAdminLogin) {
        await this.deleteMerchantGroup({ id, formData })
          .then((res) => {
            this.$vs.loading.close();
            this.$vs.notify({
              subject: "Delete Group",
              text: res.data.message,
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "success",
            });
            this.$router.push({
              name: "partner-groups",
            });
          })
          .catch((err) => {
            this.$vs.loading.close();
            this.$vs.notify({
              title: "Group",
              text: err.response.data.message,
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger",
              position: "top-right"
            });
            if(err.response.status == 404) {
              this.isSaved = true;
              this.$router.push({ name: "partner-groups" });
            }
          });
      }
    },
    checkIfAdminLogin() {
      let loggedUser = this.$store.state.AppActiveUser;
      let userType = JSON.parse(localStorage.getItem("user")).userType;
      if (
        (loggedUser.adminId &&
          loggedUser.adminId != "" &&
          loggedUser.loginBy === "flip admin") ||
        userType === "admin"
      ) {
        this.isAdminLogin = true;
      }
    },
    checkForm() {
      this.checkFormDirty = true;
    },
  },
  beforeRouteLeave(to, from, next) {
    //check here
    if ((this.isFormDirty || this.checkFormDirty) && !this.isSaved) {
      this.nextObj = next;
      this.popupActive = true;
      localStorage.setItem("redirectTo", to.name || undefined);
    } else {
      next();
    }
  },
  watch: {
    isFormDirty(latest, previous) {
      if (latest) localStorage.setItem("formFieldUpdated", "yes");
    },

    isSaved(latest, previous) {
      if (!latest) localStorage.setItem("formFieldUpdated", "yes");
    },

    checkFormDirty(latest, previous) {
      if (latest) localStorage.setItem("formFieldUpdated", "yes");
    }
  },
  mounted() {
    this.merchantId =
      JSON.parse(localStorage.getItem("userInfo")).userType === "admin"
        ? JSON.parse(localStorage.getItem("userInfo"))._id
        : JSON.parse(localStorage.getItem("userInfo")).partnerId;
    this.groupId = this.$route.params.id;
    this.getMerchantUsers();
    if (this.$route.params.id) {
      this.getRequestTemplateByMerchantIdGroup();
      this.getHostedPaymentPageByMerchantIdGroup();
    } else {
      this.getRequestTemplateByMerchantId();
      this.getHostedPaymentPageByMerchantId();
    }
    this.checkIfAdminLogin();
  },

  computed: {
    cardStyles() {
      return {
        color: "#0f67f4",
        border: "1px solid #0f67f4",
        // box-shadow: "0 0 0 2pt #0f67f4",
        background: "#fff",
      };
    },
    user() {
      return this.$store.state.AppActiveUser;
    },
    leavePage() {
      return this.popupAnswer;
    },
    partnerId() {
      return this.user.userType === "admin"
        ? this.user._id
        : this.user.partnerId;
    },
    resultQuery(){
      if(this.searchQuery){
        let resultArray = [];
        let nameArr = this.merchantUsers.filter(data =>
        data.fullName ?
        data.fullName.toLowerCase().includes(this.searchQuery.toLowerCase()) :
        data.systemName.toLowerCase().includes(this.searchQuery.toLowerCase()));

        let emailArr = this.merchantUsers.filter(data =>
        data.email ?
        data.email.toLowerCase().includes(this.searchQuery.toLowerCase()) :
        '');

        let roleArr = this.merchantUsers.filter(data =>
        data.userType ?
        data.userType.toLowerCase().includes(this.searchQuery.toLowerCase()) :
        '');

        let contactArr = this.merchantUsers.filter(data =>
        data.phoneNumber ?
        data.phoneNumber.toLowerCase().includes(this.searchQuery.toLowerCase()) :
        '');

        resultArray = [...nameArr, ...emailArr, ...roleArr, ...contactArr]
        resultArray = resultArray.filter((v, i, a) => a.indexOf(v) === i);
        return resultArray;
      }else{
        return this.merchantUsers;
      }
    }
  },
};
</script>